<template lang="pug">
  .container
    .list
        .head.row.py-2
            .col 訂閱項目
            .col 訂閱生效日期
            .col 下期扣款日期
            .col 取消/恢復訂閱
        .row.py-2.border(v-for="item in subscriptions") 
            .col {{item.name}}
            .col {{item.startDate}}
            .col {{item.renewDate}}
            .col 
                button.btn.btn-edit-light(v-if="item.isOngoing" @click="setSuspend(true)") 取消訂閱
                button.btn.btn-edit(v-else-if="item.isSuspended" @click="setSuspend(false)") 恢復訂閱
                div(v-else) {{item.debitResultString}}
</template>

<script>
import {
  statusEnum,
  SubscribeHistoryData,
  SubscriptionSuspendStatus,
} from "../../models/Subscription";
import { history, setSubscriptionSuspendStatus } from "../../api/payment";
export default {
  data() {
    return {
      page: {
        total: 0,
        page: 1,
        per: 20,
      },
      subscriptions: [
        // new SubscribeHistoryData({
        //   name: "金卡30",
        //   startDate: new Date().addMonths(2).format("yyyy-MM-dd"),
        //   renewDate: new Date().addMonths(2).format("yyyy-MM-dd"),
        //   debitResult: statusEnum.ongoing,
        // }),
        // new SubscribeHistoryData({
        //   name: "銀卡30",
        //   startDate: new Date().addMonths(1).format("yyyy-MM-dd"),
        //   renewDate: new Date().addMonths(1).format("yyyy-MM-dd"),
        //   debitResult: statusEnum.deducted,
        // }),
        // new SubscribeHistoryData({
        //   name: "金卡30",
        //   startDate: new Date().format("yyyy-MM-dd"),
        //   debitDate: new Date().format("yyyy-MM-dd"),
        //   debitResult: statusEnum.canceled,
        // }),
      ],
    };
  },
  mounted() {
    this.getHistory();
    console.log(this.subscriptions);
  },
  methods: {
    getHistory() {
      //   this.subscriptions.length = 0;
      history().then((res) => {
        if (!res.data) {
          return;
        }
        console.log(res);
        let { data, page } = res.data;
        console.log(data);
        this.page = page;
        this.subscriptions = data.map((item) => {
          return new SubscribeHistoryData({
            name: item.description,
            startDate: item.subscribed_date,
            renewDate: item.renew_date,
            status: item.status,
          });
        });
      });
    },
    setSuspend(isOngoing) {
      let title = isOngoing ? "確定要停止訂閱嗎？" : "確定要恢復訂閱嗎？";
      let status = SubscriptionSuspendStatus.cancel;
      if (!isOngoing) {
        status = SubscriptionSuspendStatus.resume;
      }

      this.$confirm(title).then(() => {
        setSubscriptionSuspendStatus(status).then((res) => {
          console.log(res.data);
          this.getHistory();
        });
      });
    },
  },
  computed: {
    statusEnum() {
      return statusEnum;
    },
  },
};
</script>

<style lang="sass" scoped></style>
