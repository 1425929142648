<template lang="pug">
  #app.h-100
      Nav.fixed-top
      router-view.main
        
        
</template>

<script>
import Nav from "./components/Navigation.vue";
// import JWT from './components/utilities/jwt'

export default {
  name: "App",
  created() {},
  beforeMount() {
    this.prepare();
  },
  mounted() {
    //告訴app網頁已經完成，如果app有token會在這時候注入
    this.$appBridge.loaded();
    if (this.$appBridge.isApp() && !this.$store.state.user) {
      this.$appBridge.logout();
      console.log("APP out");
    } else if (this.$appBridge.isApp() && this.$store.state.user) {
      this.$appBridge.login(this.$store.getters.userToken);
    }
    this.logVersion();
  },
  methods: {
    logVersion() {
      console.warn("Version: 1.4.2");
    },
    prepare() {
      this.getMaterials();
    },
    getMaterials() {
      let self = this;
      this.$api
        .getMaterials()
        .then((res) => {
          if (res.data) {
            let materials = res.data.map((v) => {
              v.selected = true;
              return v;
            });
            self.$store.commit("updateMaterialList", materials);
            this.$bus.$emit("materialListUpdate", undefined);
          }
        })
        .catch((error) => {
          console.log("getMaterials error", error);
        });
    },
  },
  components: {
    Nav,
  },
};
</script>

<style>
#app {
  position: relative;
  display: flex;
  flex-direction: column;
}
a {
  color: black;
}
/* html,body{
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
    font-size: 15px;
} */
.main {
  flex: 1;
  position: relative;
  /* height: 100%; */
  padding-top: 70px;
  align-items: stretch;
  /* margin-bottom: 55px */
}
.main::after {
  clear: both;
}
.hover_pointer:hover {
  cursor: pointer;
}
.cubiio-button-large {
  background: #ff6b00;
  box-shadow: inset -2px -2px 2px #d95b00;
  border-radius: 5px;
  padding: 10px;
  /* font-weight: 500; */
  /* font-size: 30px; */
  /* line-height: 41px; */
  /* letter-spacing: 0.15em; */
  color: white;
  /* margin-top: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cubiio-button-large:disabled {
  background: #ffb37c;
  box-shadow: inset 0px -5px 0px #d95b00;
  cursor: default;
}

.cubiio-button-large:hover {
  cursor: pointer;
}

.cubiio-input-large::placeholder {
  font-size: 1rem;
  /* line-height: 33px; */
}

.error {
  color: red;
}

.label-LC35 {
  /* 標籤邊框 */
  /* height: 17px;
  width: 35px; */
  padding: 3px;
  border: 1px solid #3ed1ff;
  box-sizing: border-box;
  border-radius: 1px;
  /* 標籤字型 */
  /* line-height: 1.5rem; */
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  /* 標籤顏色 */
  background: #fff;
  color: #3ed1ff;
  text-align: center;
  margin: auto auto auto 5px;
}

.label-LC50 {
  /* 標籤邊框 */
  /* height: 17px;
  width: 30px; */
  padding: 3px;
  border: none;
  box-sizing: border-box;
  border-radius: 1px;
  /* 標籤字型 */
  /* line-height: 1.5rem; */
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  /* 標籤顏色 */
  background: #3ed1ff;
  color: #fff;
  text-align: center;
  margin: auto auto auto 5px;
}

input:disabled,
textarea:disabled {
  border: none;
  background-color: #fff;
}
textarea {
  resize: none;
}

.btn-edit {
  background-color: #ff6b00;
  font-size: 0.8rem;
  /* width:15vw; */
  /* line-height: 30px; */
  color: #fff;
  /* padding: 5px; */
}
.btn-edit-light {
  background-color: #ffdbc1;
  font-size: 0.8rem;
  /* width:15vw; */
  /* line-height: 30px; */
  color: #ff6b00;
  /* padding: 5px; */
}
.btn-cancel {
  font-size: 0.8rem;
  /* padding: 5px; */
  background-color: #fff;
  /* width:15vw; */
  /* line-height: 30px; */
  color: #ff6b00;
  border: 1px solid #ff6b00;
  /* margin-right: 10px; */
}

.square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.square > .inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.inner > img {
  object-fit: contain;
}

*::-webkit-scrollbar {
  display: none;
}
</style>
