<template lang="pug">
    .page
        ul.nav.justify-content-center.border-bottom.p-3
            li.nav-item(v-for="item in navItem")
                router-link.nav-link(:to="item.to",:class="{'border-bottom':isSelected(item.to)}") {{item.name}}
            
        router-view
        .footer
</template>
<script>
export default {
  data() {
    return {
      id: undefined,
      navItem: [
        {
          name: this.$t("utility.profile"),
          to: "/me/profile/" + this.id,
        },
        {
          name: `${this.$t("label.myProjects")}(${this.projectUploadInfo()})`,
          to: "/me/project",
        },
        {
          name: this.$t("label.downloaded"),
          to: "/me/downloaded",
        },
        {
          name: this.$t("utility.favorites"),
          to: "/me/favorites",
        },
        {
          name: this.$t("utility.coupon"),
          to: "/me/coupon",
        },
        {
          name: this.$t("utility.subscribeHistory"),
          to: "/me/subscribe/history",
        },
        {
          name: this.$t("label.group"),
          to: "/me/group",
        },
      ],
    };
  },
  beforeMount() {
    this.id = this.$store.state.user
      ? this.$store.state.user.user.id
      : undefined;
    if (!this.id) {
      this.$router.push("/");
    }
    this.navItem[0].to = "/me/profile/" + this.id;
    if (this.$store.getters.isContractUser == true) {
      let a = {
        name: this.$t("utility.uploadedDoodle"),
        to: "/me/mydoodle",
      };
      this.navItem.push(a);
    }
  },
  methods: {
    projectUploadInfo() {
      let user = this.$store.state.user;
      if (!user) {
        return "";
      }
      user = user.user;
      if (!user || !user.upload) {
        return "";
      }
      return user.upload.used + "/" + user.upload.total;
    },
    isSelected(link) {
      return link == this.$router.currentRoute.path;
    },
  },
};
</script>
<style lang="sass" scoped>
.footer
    height: 100px
</style>
