import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import storeFactory from './store'
import routerFactory from './router'
import axios from 'axios'
import api from './api';
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import bus from './components/EventBus'
import Bridge from './components/utilities/bridge'
import InfiniteLoading from 'vue-infinite-loading';
import VueClipboard from 'vue-clipboard2'
import instance from './api/instance'

import VueI18n from 'vue-i18n' // 引入 Vue I18n
import zh from './i18n/zh' // 存放中文語系檔
import en from './i18n/en' // 存放英文語系檔

//Extensions
import {} from "./utils/DateExtension"


import VueSimpleAlert from "vue-simple-alert";
import VueLoadScript from 'vue-load-script-plus'
import JWT from './components/utilities/jwt'
import {
  refreshToken
} from './api/login.js'
import {
  loginByToken
} from './api/login.js'

setupAxiosErrorHandler(instance)
Vue.use(VueLoadScript)
Vue.use(Bridge)
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueSimpleAlert);
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Vue.use(api)
Vue.use(bus)
Vue.use(InfiniteLoading, {
  slots: {
    noResults: '',
    noMore: '', // you can pass a string value
  },
})
Vue.use(VueI18n)

let store = storeFactory()
let userInStorage = window.localStorage.getItem("cub_user")
// console.log(userInStorage)
if (userInStorage) {
  try {
    //先將舊資料顯示
    let jsonUser = JSON.parse(userInStorage)
    //token獲得新資料
    if (jsonUser.token) {
      if (JWT.expired(jsonUser.token)) {
        console.warn("token expired")
        // Try to refresh token
        refreshToken(jsonUser.token).then(res => {
          console.debug(res)
          if (res.data && res.data.token) {
            jsonUser.token = res.data.token
            loginByTokenFunc(jsonUser)
          } else {
            console.log("登出啦")
            logout()
          }
        }).catch((err) => {
          console.debug(err)
          logout()
        })

      } else {
        console.debug("token not expired")
        store.commit("updateUser", jsonUser)
        loginByTokenFunc(jsonUser)
      }
    }
  } catch (error) {
    console.log("Parse user info fail", error)
    window.localStorage.removeItem("cub_user")
  }
}

function logout() {
  // this.$appBridge.logout()
  this.$store.commit("updateUser", undefined)
  window.localStorage.removeItem("cub_user")
}

function loginByTokenFunc(jsonUser) {
  loginByToken(jsonUser.token).then(res => {
    if (res.error) {
      //登出
      console.log("login by token error", res.error)
      window.localStorage.removeItem("cub_user")
      //  this.$appBridge.logout()
      store.commit("updateUser", undefined)
      return
    } else {
      let data = res.data
      // console.log("login by token data",data)
      //  this.$appBridge.login(jsonUser.token)
      store.commit("updateUser", {
        user: data,
        token: jsonUser.token
      })
    }
    // console.log(res.data)
  }).catch(error => {
    console.log(error)
  })
}


let router = routerFactory()

router.beforeEach((to, from, next) => {
  // console.log(store.getters.userToken)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.userToken == undefined) {
      next({
        path: '/login',
      })
    } else {
      if (to.matched.some(record => record.meta.contractorOnly)) {
        if (store.getters.isContractUser == true) {
          console.log(store.getters.isContractUser)
          next()
        } else {
          next({
            path: '/',
          })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.contractorOnly)) {
    if (store.getters.isContractUser == true) {
      console.log(store.getters.isContractUser)
      next()
    } else {
      next({
        path: '/me/profile',
      })
    }
  } else {
    next()
  }
})

// 預設使用的語系
let locale = 'en';

// 檢查 localStorage 是否已有保存使用者選用的語系資訊
if (localStorage.getItem('footmark-lang')) {
  locale = localStorage.getItem('footmark-lang');
}
store.dispatch('setLang', locale);

const i18n = new VueI18n({
  locale: locale,
  messages: {
    'zh': zh,
    'en': en
  }
});



let vm = new Vue({
  render: h => h(App),
  i18n,
  router,
  store,

}).$mount('#app')
// window.addEventListener("focus", function() 
// { 
//   console.log("window focused")
// }, false);

window.loginFromApp = function loginFromApp(token) {
  window.token = token
  vm.$api.loginByToken(token).then(res => {
    let user = {
      token,
      user: res.data
    }
    vm.$store.commit('updateUser', user)
    vm.$appBridge.loaded();
    if (vm.$appBridge.isApp() && !vm.$store.state.user) {
      vm.$appBridge.logout();
      console.log("APP out");
    } else if (vm.$appBridge.isApp() && vm.$store.state.user) {
      vm.$appBridge.login(vm.$store.getters.userToken);
    }
    // console.log("login by token",user)
  })
}


function setupAxiosErrorHandler(instance) {
  instance.interceptors.response.use(
    function (response) {
      // console.log("axios.interceptors.response")
      // console.log(response)
      return response;
    },
    function (error) {
      // console.log("axios.interceptors.error")
      return Promise.reject(error);
      // check for errorHandle config
      // if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
      //     return Promise.reject(error);
      // }

      // if (error.response.status == 401) {
      //   console.log("axios.interceptors.error.401")
      //     store.dispatch("user/logout").then(()=>{
      //       router.replace("/login")
      //     })
      // }
    });
}